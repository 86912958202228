@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Roboto', sans-serif;
}

html,
body,
#root {
    @apply h-full w-full relative;
    background: #efefef;
    height: 100% !important;
}

.safe{
    @apply container mx-auto px-2;
}

.marquee {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}

.marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 30s linear infinite;
}

@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

.link{
    @apply underline flex items-center cursor-pointer;
}

table.result td, table.result th {
    @apply py-1 px-2 border;
}

.swal2-image{
    @apply mt-12 mb-12;
}

.swal2-close{
    box-shadow: none !important;
}

.result-map svg{
    width: 100%;
    height: 500px;
}


.bg-glass{
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 255, 255, 255, 0.37 );
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 5px );
}

.button{
    @apply p-2 text-xs uppercase hover:opacity-80 z-10 border bg-white;
}

.button.active{
    @apply bg-red-100 text-white;
}